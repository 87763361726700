import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "yr-practice-interval-indicator",
  templateUrl: "./practice-interval-indicator.component.html",
  standalone: true,
  imports: [TranslateModule],
  styleUrls: [],
})
export class PracticeIntervalIndicatorComponent {
  interval = input.required<number | undefined>();
}
