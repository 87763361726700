<span
  class="rounded-lg border-[1px] border-primary bg-white p-1 text-sm text-primary"
>
  @if (interval() == undefined || interval() == 0) {
    {{ "practice-routines.practice-interval-indicator.never" | translate }}
  } @else if (interval() == 1) {
    {{ "practice-routines.practice-interval-indicator.daily" | translate }}
  } @else if (interval() == 7) {
    {{ "practice-routines.practice-interval-indicator.weekly" | translate }}
  } @else if (interval() == 30) {
    {{ "practice-routines.practice-interval-indicator.monthly" | translate }}
  }
</span>
